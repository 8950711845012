import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabSwitch } from "@CabComponents/CabSwitch";

import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { Box, SelectChangeEvent, SxProps, Typography } from "@mui/material";

import { 
  GridCallbackDetails, GridColDef, GridFilterModel, GridRenderCellParams,
  GridSortModel, GridToolbarFilterButton, GridToolbarColumnsButton, GridPaginationModel, DataGridProProps 
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useMemo } from "react";
import colors from "../../colors";
import { User } from "../../store";
import { AnalyticsEvent, EventAnalyticsState } from "../../store/eventAnalytics/types";
import { containsOperator, selectFilterOperator } from "../../components/DataGrid/utils";
import NoRowOverlay from "../../components/EventAnalytics/NoRowsOverlay";
import { CabButton } from "@CabComponents/CabButton";
import { IoDownloadOutline, IoCreateOutline } from "react-icons/io5";
import CabDataGrid from "@CabComponents/CabDataGrid";
import { createSelectOptions } from "../../components/EventAnalytics/utils";


export type ReconciliationGridProps = {
  rows: AnalyticsEvent[];
  event_categories: EventAnalyticsState["event_categories"];
  event_types: EventAnalyticsState["event_types"];
  consolidated_locations: EventAnalyticsState["consolidated_locations"];
  executive_contacts: EventAnalyticsState["executive_contacts_reconciliation"];
  recurrence_types: EventAnalyticsState["recurrence_types"];
  analysis_inclusions: EventAnalyticsState["analysis_inclusions"];
  loading: boolean;
  handlePageChange: (newPage: number) => void;
  page: number;
  totalRows: number | undefined;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => void;
  user: User | null | undefined;
  handleUpdateEvent: (event: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'>) => void;
  sx?: SxProps;
  fetchExportData: () => void;
  handleEditLabelModal: () => void
};

const initialState: DataGridProProps["initialState"] = {
  pinnedColumns: {
    left: ["title"]
  }
};

const ReconciliationGrid = ({
  rows, event_categories, event_types,
  executive_contacts, consolidated_locations,
  loading, handlePageChange, totalRows, page,
  handleSortModelChange, handleFilterModelChange,
  user, handleUpdateEvent, sx, recurrence_types,
  fetchExportData, analysis_inclusions, handleEditLabelModal
}: ReconciliationGridProps) => {

  const renderAttendees = useCallback((
    {row: { attendees }}: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    const attendeeList = attendees?.map((attendeeId) => {
      const attendee = executive_contacts[attendeeId.toString()];
      return attendee ? attendee.primary_email ?? attendee.primary_email_hashed.slice(0, 8) : "";
    }) || [];
    return <div>
      <CabTooltip 
        title={attendeeList.map(attendeeEmail => {
          return <span key={attendeeEmail}>{attendeeEmail}<br /></span>;
        })} 
        placement="bottom">
        <>{attendeeList[0]}</>
        <>
          {attendeeList.length > 1 && (
            <Box display='flex'>
              <Typography>+</Typography>
              {attendeeList.length - 1} more
            </Box>
          )}
        </>
      </CabTooltip>
    </div>;
  }, [executive_contacts]);

  const renderStartDate = useCallback((
    {row: { start_date }}: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ) => {
    return <div>
      {DateTime.fromISO(start_date).toFormat("M/dd/yyyy")}
    </div>;
  }, []);

  // This updates event_category and event_type. These two objects
  // Have the same data structure but are stored on different db tables.
  // The function is abstracted to reduce duplicate code.

  const updateEventMeta = useCallback(
    (
      e: SelectChangeEvent<number>,
      id: number,
      prop: "event_category" | "event_type" | "inclusion",
      leader: number,
      recurring_root_event_idr: string | null
    ) => {
      let value = e.target?.value ? Number(e.target?.value) : null;
      value = value === -1 ? null : value;
      handleUpdateEvent(
        { id, [prop]: value, leader, recurring_root_event_idr: recurring_root_event_idr || null }
      );
    }, [handleUpdateEvent]);


  const renderCategories = useCallback((
    { row: { id, event_category, leader, recurring_root_event_idr } }: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    const options = createSelectOptions(Object.values(event_categories), user?.permissions.CONTENT_EDITOR);

    return <CabDropdown<number>
      options={options}
      value={event_category || -1}
      onChange={(e) => updateEventMeta(e, id, "event_category", leader, recurring_root_event_idr)}
      overrides={{
        renderValue: (value: unknown) => {
          const eventId = value as number;
          const eventCategoryObject = eventId > 0 ?
            event_categories[eventId] :
            {id: -1, name: "None", color: colors.white800};
          return <CabToggleChip 
            chipColor={eventCategoryObject.color}
            label={eventCategoryObject.name}
            selected={true}
          />;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
        }
      }}
    />;
  }, [event_categories, user?.permissions.CONTENT_EDITOR, updateEventMeta]);

  const renderTypes = useCallback((
    { row: { id, event_type, leader, recurring_root_event_idr } }: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    const options = createSelectOptions(Object.values(event_types), user?.permissions.CONTENT_EDITOR);
    return <CabDropdown<number>
      options={options}
      value={event_type || -1}
      onChange={(e) => updateEventMeta(e, id, "event_type", leader, recurring_root_event_idr)}
      overrides={{
        renderValue: (value: unknown) => {
          const eventId = value as number;
          const eventTypeObject = eventId > 0 && event_types[eventId]
            ? event_types[eventId] 
            : {id: -1, name: "None", color: colors.white800};
          return <CabToggleChip chipColor={eventTypeObject.color} label={eventTypeObject.name || ''} selected={true} />;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
        }
      }}
    />;
  }, [event_types, updateEventMeta, user?.permissions.CONTENT_EDITOR]);

  const renderRecurrence = useCallback((
    { row: { recurrence_type } }: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    const recurrence = recurrence_types[recurrence_type];
    return <CabToggleChip 
      chipColor={recurrence?.color || colors.white800}
      label={recurrence?.name}
      selected={true}
    />;
  }, [recurrence_types]);

  const locMap: {[key: string]: string} = useMemo(() => ({
    "None": "No Location",
    "other": "Other",
    "phone": "Phone",
    "no_location": "",
    "virtual_conference": "Web Conference",
    "in_person": "In-Person",
  }), []);

  const locNameToIdMap: {[key: string]: string} = useMemo(() => {
    const nameToIdMap: {[key: string]: string} = {};
    Object.values(consolidated_locations).forEach(val => {
      nameToIdMap[locMap[val.name]] = String(val.id).toString();
    });
    return nameToIdMap;
  }, [consolidated_locations, locMap]);

  const renderLocations = useCallback((
    { row: { consolidated_location } }: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    const location = consolidated_locations[consolidated_location || 0];


    const locationList = (location?.name || "None").split(",").filter(loc => (loc !== "no_location")).map(loc => {
      return locMap[loc];
    }) || [];

    return <div>
      {locationList.length > 0 && (
        <CabTooltip 
          title={locationList.map(locationName => {
            return <span key={locationName}>{locationName}<br /></span>;
          })}  
          placement="bottom">
          <CabToggleChip
            key={locationList[0]}
            chipColor={consolidated_locations[locNameToIdMap[locationList[0]]]?.color || colors.white800}
            label={locationList[0]}
            selected={true}
          />
          <>
            {locationList.length > 1 && (
              <Box display='flex'>
                <Typography>+</Typography>
                {locationList.length - 1} more
              </Box>
            )}
          </>
        </CabTooltip>
      )}
    </div>;
  }, [consolidated_locations, locMap, locNameToIdMap]);

  const renderInclusion = useCallback((
    { row: { id, inclusion, leader, recurring_root_event_idr } }: GridRenderCellParams<AnalyticsEvent, ReactNode>
  ): ReactNode => {
    return <Box display="flex" width="100%">
      <CabSwitch
        checked={inclusion !== 1}
        onChange={(e) => {
          e.target.value = inclusion === 1 ? "2" : "1";
          updateEventMeta(e, id, "inclusion", leader, recurring_root_event_idr);
        }}
      />
    </Box>;
  }, [updateEventMeta]);

  const eventCategoryOptions = useMemo(
    () => createSelectOptions(Object.values(event_categories), user?.permissions.CONTENT_EDITOR, true),
    [event_categories, user],
  );

  const eventTypeOptions = useMemo(
    () => createSelectOptions(Object.values(event_types), user?.permissions.CONTENT_EDITOR, true),
    [event_types, user],
  );

  const recurrenceOptions = useMemo(
    () => createSelectOptions(Object.values(recurrence_types), user?.permissions.CONTENT_EDITOR, true),
    [recurrence_types, user],
  );

  const locationOptions = useMemo(
    () => createSelectOptions(
      Object.values(consolidated_locations).map(
        cat => ({id: cat.id, color:cat.color, name: cat.name.split(",").map(item => locMap[item]).join(", ")})),
      user?.permissions.CONTENT_EDITOR,
      true
    )
    , [consolidated_locations, locMap, user]
  );

  const inclusionOptions = useMemo(
    () => createSelectOptions(
      Object.values(analysis_inclusions),
      user?.permissions.CONTENT_EDITOR,
      true,
      false
    )
    , [analysis_inclusions, user]
  );

  const filteringEnabled = true;

  const columns: GridColDef<AnalyticsEvent>[] = useMemo(() => {
    const cols: GridColDef<AnalyticsEvent>[] = [
      {
        field: 'title',
        headerName: 'Meeting Name',
        display: "flex",
        flex: 1,
        minWidth: 250,
        editable: false,
        filterable: false
      },
      {
        field: 'start_date',
        headerName: 'Meeting Date',
        type: "date",
        display: "flex",
        width: 105,
        editable: false,
        valueGetter: (value, row, column, apiRef) => DateTime.fromISO(value).toJSDate(),
        renderCell: renderStartDate,
        filterable: false
      },
      {
        field: 'attendee_lookup',
        headerName: 'Attendees',
        type: "string",
        display: "flex",
        width: 165,
        editable: false,
        filterable: filteringEnabled,
        renderCell: renderAttendees,
        filterOperators: containsOperator
      },
      {
        field: 'event_category',
        headerName: 'Category',
        type: 'singleSelect',
        display: "flex",
        valueOptions: [],
        width: 170,
        filterable: filteringEnabled,
        renderCell: renderCategories,
        filterOperators: selectFilterOperator([
          { options: eventCategoryOptions, rawOptions: event_categories },
        ]).map(operator => {
          return {
            ...operator,
            getValueAsString: (value: number) => {
              if (Array.isArray(value)) {
                return value.map(v => Object.values(event_categories).find(e => e.id === v)?.name || 'None').join(', ');
              }
              return Object.values(event_categories).find(e => e.id === value)?.name || 'None';
            }
          };
        })
      },
      {
        field: 'event_type',
        headerName: 'Type',
        type: 'singleSelect',
        width: 170,
        display: "flex",
        editable: false,
        filterable: filteringEnabled,
        valueOptions: [],
        renderCell: renderTypes,
        filterOperators: selectFilterOperator([
          { options: eventTypeOptions, rawOptions: event_types }
        ]).map(operator => {
          return {
            ...operator,
            getValueAsString: (value: number) => {
              if (Array.isArray(value)) {
                return value.map(v => Object.values(event_types).find(e => e.id === v)?.name || 'None').join(', ');
              }
              return Object.values(event_types).find(e => e.id === value)?.name || 'None';
            }
          };
        })
      },
      {
        field: 'recurrence_type',
        headerName: 'Frequency',
        type: 'singleSelect',
        width: 150,
        display: "flex",
        editable: false,
        valueOptions: [],
        filterable: filteringEnabled,
        renderCell: renderRecurrence,
        filterOperators: selectFilterOperator([
          { options: recurrenceOptions, rawOptions: recurrence_types }
        ]).map(operator => {
          return {
            ...operator,
            getValueAsString: (value: number) => {
              if (Array.isArray(value)) {
                return value.map(v => Object.values(recurrence_types).find(e => e.id === v)?.name || 'None').join(', ');
              }
              return Object.values(recurrence_types).find(e => e.id === value)?.name || 'None';
            }
          };
        })
      },
      {
        field: 'consolidated_location',
        headerName: 'Location',
        type: 'singleSelect',
        width: 160,
        display: "flex",
        editable: false,
        filterable: filteringEnabled,
        valueOptions: [],
        renderCell: renderLocations,
        filterOperators: selectFilterOperator([
          { options: locationOptions, rawOptions: consolidated_locations }
        ]).map(operator => {
          return {
            ...operator,
            getValueAsString: (value: number) => {
              if (Array.isArray(value)) {
                return value.map(v => Object.values(consolidated_locations).map(
                  cat => ({id: cat.id, color:cat.color, name: cat.name.split(",")
                    .map(item => locMap[item]).join(", ")}))
                  .find(e => e.id === v)?.name || 'None').join(', ');
              }
              return Object.values(consolidated_locations)
                .map(cat => ({id: cat.id, color:cat.color, name: cat.name.split(",")
                  .map(item => locMap[item]).join(", ")})).find(e => e.id === value)?.name || 'None';
            }
          };
        })
      },
      {
        field: 'inclusion',
        headerName: 'Ignore',
        type: 'singleSelect',
        width: 160,
        display: "flex",
        editable: false,
        filterable: filteringEnabled,
        valueOptions: [],
        renderCell: renderInclusion,
        filterOperators: selectFilterOperator([
          { options: inclusionOptions, rawOptions: analysis_inclusions }
        ])
      },
      {
        field: '',
        headerName: '',
        minWidth: 0,
        flex: 1,
        display: "flex",
        disableColumnMenu: true,
        resizable: false,
        hideable: false,
        filterable: false,
        sortable: false,
      },
    ];
    return cols;
  }, [renderStartDate, filteringEnabled, renderAttendees, renderCategories, eventCategoryOptions, 
    event_categories, renderTypes, eventTypeOptions, event_types, renderRecurrence, recurrenceOptions, 
    recurrence_types, renderLocations, locationOptions, consolidated_locations, renderInclusion, inclusionOptions, 
    analysis_inclusions, locMap]);

  const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
    handlePageChange(model.page);
  }, [handlePageChange]);

  const paginationModel = useMemo(() => ({
    page: page,
    pageSize: totalRows && totalRows > 20 ? 20 : totalRows || 0,
  }), [page, totalRows]);

  const pageSizeOptions = useMemo(() => (
    totalRows !== undefined ? [totalRows > 20 ? 20 : totalRows] : [0]
  ), [totalRows]);

  const slots = useMemo(() => ({
    loadIcon: () => <></>,
    noRowsOverlay: () => (
      <NoRowOverlay emptyMessage="No Reconciliation Data Available" msTimeout={5000}/>
    ),
    toolbar: filteringEnabled ? () => <Box display="flex" flexDirection="row-reverse" sx={{width: "100%"}}>
      <CabTooltip title={"Download table data to CSV file"} >
        <Box display="flex" alignItems="center"  onClick={fetchExportData} sx={{cursor: "pointer"}}>
          <CabIcon 
            alt='Export'
            size='small'
            Icon={IoDownloadOutline}
            sx={{color: colors.greenPrimary, marginRight: 1}}
          />
          <Typography sx={{fontSize: "13px", color: colors.greenPrimary, fontWeight: 500}}>
            Export
          </Typography>
        </Box>
      </CabTooltip>
      <CabButton
        icon={<CabIcon 
          alt='Labels'
          size='small'
          Icon={IoCreateOutline}
        />}
        onClick={handleEditLabelModal}
        buttonType="tertiary"
        sx={{
          cursor: "pointer",
          fontSize: "13px",
          border: 0,
          paddingRight: 1,
          paddingLeft: 1,
          "&:hover": {
            border: 0
          }
        }}
      >
        Labels
      </CabButton>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </Box> : undefined
  }), [fetchExportData, filteringEnabled, handleEditLabelModal]);

  return (
    <Box sx={sx}>
      <CabDataGrid<AnalyticsEvent>
        rows={rows}
        loading={false}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        checkboxSelection={false}
        disableRowSelectionOnClick
        disableVirtualization
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        rowCount={totalRows || 0}
        initialState={initialState}
        slots={slots}
      />
    </Box>
  );
};

export default ReconciliationGrid;